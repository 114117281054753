[class*="info-box__wrapper"] {
  transition: all .4s ease; }
  [class*="info-box__wrapper"] p {
    margin-top: 25px; }
  [class*="info-box__wrapper"] svg {
    transition: all .4s ease;
    width: 84px;
    height: 84px; }
    [class*="info-box__wrapper"] svg + * {
      margin-top: 10px; }
  [class*="info-box__wrapper"] i[class*='fa-'] {
    font-size: 5rem; }
    [class*="info-box__wrapper"] i[class*='fa-'] + * {
      margin-top: 15px; }

[class*="col-"]:last-child .info-box__wrapper-outline {
  border-right: 1px solid rgba(255, 255, 255, 0.32); }

.wpb_column.current .info-box__wrapper-chess-chess:after {
  height: 100%; }

.wpb_column.current .info-box__wrapper-chess-chess.bottom .show-cont {
  bottom: 100%;
  transform: translateY(100%); }

.wpb_column.current .info-box__wrapper-chess-chess .show-cont.overlay__gradient > .overlay_after {
  opacity: 0 !important; }

.wpb_column.current .info-box__wrapper-chess-chess .show-cont > .after {
  opacity: 0; }

.wpb_column.current .info-box__wrapper-chess-chess .show-cont > * {
  color: #333; }

.wpb_column.current .info-box__wrapper-chess-chess svg * {
  stroke: var(--brand-primary); }

.wpb_column.current .info-box__wrapper-chess-chess .move-cont {
  opacity: 1; }

.info-box__wrapper-chess {
  position: relative;
  z-index: 1;
  min-height: 430px; }
  .info-box__wrapper-chess:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    transition: all .4s ease;
    background: #fff;
    box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.12);
    z-index: -1; }
  .info-box__wrapper-chess.bottom:after {
    bottom: 0;
    top: auto; }
  .info-box__wrapper-chess.bottom .show-cont {
    bottom: 0;
    top: auto; }
  .info-box__wrapper-chess .btn {
    margin: 40px 0 0;
    min-width: 0;
    width: 100%; }
  .info-box__wrapper-chess .show-cont {
    padding: 65px 30px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transition: all .4s ease;
    z-index: 1; }
    .info-box__wrapper-chess .show-cont > * {
      transition: all .4s ease; }
    .info-box__wrapper-chess .show-cont > .after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: -1;
      transition: all .4s ease; }
    .info-box__wrapper-chess .show-cont.overlay__gradient > .overlay_after {
      transition: all .4s ease; }
    .info-box__wrapper-chess .show-cont > * {
      color: #fff; }
  .info-box__wrapper-chess .move-cont {
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 30px;
    opacity: 0;
    transition: all .4s ease; }
  .info-box__wrapper-chess .livicon-evo {
    margin-bottom: 10px; }
  .info-box__wrapper-chess svg * {
    stroke: #fff; }
  @media (max-width: 991px) {
    .info-box__wrapper-chess:after {
      height: 100%; }
    .info-box__wrapper-chess.bottom .show-cont {
      bottom: 100%;
      transform: translateY(100%); }
    .info-box__wrapper-chess .show-cont.overlay__gradient > .overlay_after {
      opacity: 0 !important; }
    .info-box__wrapper-chess .show-cont > .after {
      opacity: 0; }
    .info-box__wrapper-chess .show-cont > * {
      color: #333; }
    .info-box__wrapper-chess svg * {
      stroke: var(--brand-primary); }
    .info-box__wrapper-chess .move-cont {
      opacity: 1; } }
  .info-box__wrapper-chess:hover:after, .info-box__wrapper-chess.current:after {
    height: 100%; }
  .info-box__wrapper-chess:hover.bottom .show-cont, .info-box__wrapper-chess.current.bottom .show-cont {
    bottom: 100%;
    transform: translateY(100%); }
  .info-box__wrapper-chess:hover .show-cont.overlay__gradient > .overlay_after, .info-box__wrapper-chess.current .show-cont.overlay__gradient > .overlay_after {
    opacity: 0 !important; }
  .info-box__wrapper-chess:hover .show-cont > .after, .info-box__wrapper-chess.current .show-cont > .after {
    opacity: 0; }
  .info-box__wrapper-chess:hover .show-cont > *, .info-box__wrapper-chess.current .show-cont > * {
    color: #333; }
  .info-box__wrapper-chess:hover i[class*='fa-'], .info-box__wrapper-chess.current i[class*='fa-'] {
    color: var(--brand-primary); }
  .info-box__wrapper-chess:hover svg *, .info-box__wrapper-chess.current svg * {
    stroke: var(--brand-primary); }
  .info-box__wrapper-chess:hover .move-cont, .info-box__wrapper-chess.current .move-cont {
    opacity: 1; }

.wpb_column.current .info-box__wrapper-outline:after {
  top: -30px;
  left: -10px;
  right: -10px;
  bottom: -30px;
  opacity: 1; }

.wpb_column.current .info-box__wrapper-outline .move-cont {
  transform: translateY(-50px); }
  .wpb_column.current .info-box__wrapper-outline .move-cont > * {
    color: #333; }
  .wpb_column.current .info-box__wrapper-outline .move-cont p {
    color: #9F9F9F;
    opacity: 1; }

.wpb_column.current .info-box__wrapper-outline svg * {
  stroke: var(--brand-primary); }

.info-box__wrapper-outline {
  border: 1px solid rgba(255, 255, 255, 0.32);
  padding: 85px 20px;
  position: relative;
  z-index: 1; }
  @media (min-width: 992px) {
    .info-box__wrapper-outline {
      border-right: none; } }
  .info-box__wrapper-outline:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: -1;
    opacity: 0;
    transition: all .4s ease;
    box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.12); }
  .info-box__wrapper-outline p {
    position: absolute;
    opacity: 0; }
  .info-box__wrapper-outline .move-cont {
    transition: all .4s ease; }
    .info-box__wrapper-outline .move-cont > * {
      transition: all .4s ease;
      color: #fff; }
  .info-box__wrapper-outline .livicon-evo {
    margin-bottom: 10px; }
  .info-box__wrapper-outline svg * {
    stroke: #fff; }
  .info-box__wrapper-outline:hover:after, .info-box__wrapper-outline.current:after {
    top: -30px;
    left: -10px;
    right: -10px;
    bottom: -30px;
    opacity: 1; }
  .info-box__wrapper-outline:hover .move-cont, .info-box__wrapper-outline.current .move-cont {
    transform: translateY(-50px); }
    .info-box__wrapper-outline:hover .move-cont > *, .info-box__wrapper-outline.current .move-cont > * {
      color: #333; }
    .info-box__wrapper-outline:hover .move-cont p, .info-box__wrapper-outline.current .move-cont p {
      color: #9F9F9F;
      opacity: 1; }
  .info-box__wrapper-outline:hover svg *, .info-box__wrapper-outline.current svg * {
    stroke: var(--brand-primary); }

.wpb_column.current .info-box__wrapper-gradient {
  z-index: 2; }
  .wpb_column.current .info-box__wrapper-gradient:after {
    top: -20px;
    left: -10px;
    right: -10px;
    bottom: -20px;
    opacity: 1; }
  .wpb_column.current .info-box__wrapper-gradient .livicon-evo {
    width: 70px !important; }
  .wpb_column.current .info-box__wrapper-gradient .move-cont {
    transform: translateY(-60px); }
    .wpb_column.current .info-box__wrapper-gradient .move-cont .show-cont {
      color: #9F9F9F;
      opacity: 1; }
  .wpb_column.current .info-box__wrapper-gradient .svg-wrap {
    background: #eef4ff; }
  .wpb_column.current .info-box__wrapper-gradient svg {
    width: 65px;
    height: 65px; }

.info-box__wrapper-gradient {
  border: 1px solid rgba(var(--brand-primary-rgb), 0.12);
  padding: 85px 20px;
  position: relative;
  z-index: 1;
  background: #fff; }
  @media (min-width: 992px) {
    .info-box__wrapper-gradient {
      border-right: none; } }
  .info-box__wrapper-gradient .livicon-evo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all .4s ease; }
    .info-box__wrapper-gradient .livicon-evo svg {
      transform: translate(0, 0) !important; }
  .info-box__wrapper-gradient:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: -1;
    opacity: 0;
    transition: all .4s ease;
    box-shadow: 0 6px 30px 0 rgba(var(--brand-primary-rgb), 0.4); }
  .info-box__wrapper-gradient .svg-wrap {
    position: relative;
    z-index: 1;
    display: inline-block;
    background: transparent;
    transition: all .4s ease;
    border-radius: 50%;
    width: 120px;
    height: 120px; }
    .info-box__wrapper-gradient .svg-wrap + * {
      margin-top: 25px; }
    .info-box__wrapper-gradient .svg-wrap svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .info-box__wrapper-gradient p {
    margin-top: 5px; }
  .info-box__wrapper-gradient .show-cont {
    position: absolute;
    opacity: 0;
    width: 100%; }
  .info-box__wrapper-gradient .move-cont {
    transition: all .4s ease; }
    .info-box__wrapper-gradient .move-cont > * {
      transition: all .4s ease; }
  .info-box__wrapper-gradient svg {
    width: 120px;
    height: 120px; }
  .info-box__wrapper-gradient svg * {
    stroke: var(--brand-primary); }
  .info-box__wrapper-gradient:hover, .info-box__wrapper-gradient.current {
    z-index: 2; }
    .info-box__wrapper-gradient:hover:after, .info-box__wrapper-gradient.current:after {
      top: -20px;
      left: -10px;
      right: -10px;
      bottom: -20px;
      opacity: 1; }
    .info-box__wrapper-gradient:hover .livicon-evo, .info-box__wrapper-gradient.current .livicon-evo {
      width: 70px !important; }
    .info-box__wrapper-gradient:hover .move-cont, .info-box__wrapper-gradient.current .move-cont {
      transform: translateY(-60px); }
      .info-box__wrapper-gradient:hover .move-cont .show-cont, .info-box__wrapper-gradient.current .move-cont .show-cont {
        color: #9F9F9F;
        opacity: 1; }
    .info-box__wrapper-gradient:hover .svg-wrap, .info-box__wrapper-gradient.current .svg-wrap {
      background: #eef4ff; }
    .info-box__wrapper-gradient:hover svg, .info-box__wrapper-gradient.current svg {
      width: 65px;
      height: 65px; }

.wpb_column.current .info-box__wrapper-shuffle {
  z-index: 2;
  padding-top: 75px;
  padding-bottom: 75px; }
  .wpb_column.current .info-box__wrapper-shuffle.bottom {
    padding-bottom: 150px; }
    .wpb_column.current .info-box__wrapper-shuffle.bottom .after-bg:after {
      top: 0; }
  .wpb_column.current .info-box__wrapper-shuffle > * {
    color: #fff; }
    .wpb_column.current .info-box__wrapper-shuffle > * > * {
      color: #fff; }
  .wpb_column.current .info-box__wrapper-shuffle .after-bg {
    opacity: 0.94;
    height: 100%; }
    .wpb_column.current .info-box__wrapper-shuffle .after-bg > .after {
      opacity: 1; }
    .wpb_column.current .info-box__wrapper-shuffle .after-bg:after {
      bottom: 0; }
  .wpb_column.current .info-box__wrapper-shuffle .btn {
    opacity: 1; }
  .wpb_column.current .info-box__wrapper-shuffle .svg-wrap {
    width: 135px;
    height: 135px;
    border-color: rgba(255, 255, 255, 0.3); }
    .wpb_column.current .info-box__wrapper-shuffle .svg-wrap + * {
      margin-top: 32px; }
  .wpb_column.current .info-box__wrapper-shuffle svg * {
    stroke: #fff; }

.info-box__wrapper-shuffle {
  margin: 30px 0;
  position: relative;
  z-index: 1;
  min-height: 520px;
  padding: 27px 30px;
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.12); }
  @media (min-width: 768px) {
    .info-box__wrapper-shuffle {
      margin: 0; } }
  .info-box__wrapper-shuffle.bottom {
    display: flex;
    align-items: flex-end; }
    .info-box__wrapper-shuffle.bottom .after-bg {
      bottom: 0;
      top: auto; }
      .info-box__wrapper-shuffle.bottom .after-bg:after {
        bottom: auto;
        top: -25px;
        border-width: 0 30px 25px 30px;
        border-color: transparent transparent #fff transparent; }
    .info-box__wrapper-shuffle.bottom .img-bottom {
      top: 0;
      bottom: auto; }
    .info-box__wrapper-shuffle.bottom .img-top {
      bottom: 0;
      top: auto; }
  .info-box__wrapper-shuffle .livicon-evo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .info-box__wrapper-shuffle .livicon-evo svg {
      transform: translate(0, 0) !important; }
  .info-box__wrapper-shuffle .img-bottom, .info-box__wrapper-shuffle .img-top {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    object-fit: cover;
    z-index: -1;
    pointer-events: none; }
  .info-box__wrapper-shuffle .img-top {
    transform: rotate(180deg);
    top: 0;
    bottom: auto; }
  .info-box__wrapper-shuffle .svg-wrap {
    width: 70px;
    height: 70px;
    position: relative;
    margin: 0 auto;
    border: 3px solid transparent;
    border-radius: 50%;
    transition: all .4s ease; }
    .info-box__wrapper-shuffle .svg-wrap + * {
      margin-top: 12px; }
    .info-box__wrapper-shuffle .svg-wrap svg {
      width: 70px;
      height: 70px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); }
  .info-box__wrapper-shuffle .after-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: #fff;
    transition: all .4s ease;
    z-index: -1; }
    .info-box__wrapper-shuffle .after-bg:after {
      content: '';
      position: absolute;
      bottom: -25px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 25px 30px 0 30px;
      border-color: #fff transparent transparent transparent;
      transition: all .4s ease;
      z-index: -1; }
    .info-box__wrapper-shuffle .after-bg > .after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to top, var(--brand-primary), var(--brk-base-2));
      transition: all .4s ease;
      opacity: 0; }
  .info-box__wrapper-shuffle p {
    color: #9F9F9F;
    margin-top: 10px;
    line-height: 21px; }
  .info-box__wrapper-shuffle .btn {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    margin: 40px 0 0;
    opacity: 0;
    transition: all .4s ease; }
  .info-box__wrapper-shuffle svg * {
    stroke: var(--brand-primary); }
  .info-box__wrapper-shuffle:hover, .info-box__wrapper-shuffle.current {
    z-index: 2;
    padding-top: 75px;
    padding-bottom: 75px; }
    .info-box__wrapper-shuffle:hover.bottom, .info-box__wrapper-shuffle.current.bottom {
      padding-bottom: 150px; }
      .info-box__wrapper-shuffle:hover.bottom .after-bg:after, .info-box__wrapper-shuffle.current.bottom .after-bg:after {
        top: 0; }
    .info-box__wrapper-shuffle:hover > *, .info-box__wrapper-shuffle.current > * {
      color: #fff; }
      .info-box__wrapper-shuffle:hover > * > *, .info-box__wrapper-shuffle.current > * > * {
        color: #fff; }
    .info-box__wrapper-shuffle:hover .after-bg, .info-box__wrapper-shuffle.current .after-bg {
      opacity: 0.94;
      height: 100%; }
      .info-box__wrapper-shuffle:hover .after-bg > .after, .info-box__wrapper-shuffle.current .after-bg > .after {
        opacity: 1; }
      .info-box__wrapper-shuffle:hover .after-bg:after, .info-box__wrapper-shuffle.current .after-bg:after {
        bottom: 0; }
    .info-box__wrapper-shuffle:hover .btn, .info-box__wrapper-shuffle.current .btn {
      opacity: 1; }
    .info-box__wrapper-shuffle:hover .svg-wrap, .info-box__wrapper-shuffle.current .svg-wrap {
      width: 135px;
      height: 135px;
      border-color: rgba(255, 255, 255, 0.3); }
      .info-box__wrapper-shuffle:hover .svg-wrap + *, .info-box__wrapper-shuffle.current .svg-wrap + * {
        margin-top: 32px; }
    .info-box__wrapper-shuffle:hover svg *, .info-box__wrapper-shuffle.current svg * {
      stroke: #fff; }

.wpb_column.current .info-box__wrapper-web .svg-wrap {
  transform: scale(1); }

.wpb_column.current .info-box__wrapper-web .shape > .before {
  animation: 1.5s brk-pulse .3s infinite;
  opacity: .1; }

.wpb_column.current .info-box__wrapper-web .shape > .after {
  animation: brk-pulse 1.5s infinite;
  opacity: .2; }

.info-box__wrapper-web {
  text-align: left;
  padding: 50px 20px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center; }
  @media (min-width: 992px) {
    .info-box__wrapper-web {
      padding: 70px 20px; } }
  .info-box__wrapper-web .content {
    margin-left: 22px;
    flex: 1; }
    .info-box__wrapper-web .content > * {
      color: #fff; }
    .info-box__wrapper-web .content p {
      color: rgba(var(--white-rgb), 0.6);
      margin-top: 15px; }
  .info-box__wrapper-web .livicon-evo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
    .info-box__wrapper-web .livicon-evo svg {
      transform: translate(0, 0) !important; }
  .info-box__wrapper-web .shape {
    position: relative;
    z-index: 1; }
    .info-box__wrapper-web .shape:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 15px;
      height: 15px;
      border: 2px solid #fff;
      border-radius: 50%; }
    .info-box__wrapper-web .shape:after {
      position: absolute;
      bottom: calc(50% + 7px);
      left: calc(50% - 1px);
      width: 2px;
      height: 100vh;
      background: #fff; }
      @media (min-width: 992px) {
        .info-box__wrapper-web .shape:after {
          content: ''; } }
    .info-box__wrapper-web .shape > .before {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform: scale(1.35);
      opacity: 0;
      background-color: #fff;
      transition: all .4s ease;
      border-radius: 50%;
      z-index: -1; }
    .info-box__wrapper-web .shape > .after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      background-color: #fff;
      transition: all .4s ease;
      border-radius: 50%;
      z-index: -1; }
  .info-box__wrapper-web .svg-wrap {
    position: relative;
    z-index: 1;
    display: inline-block;
    background: linear-gradient(to top, var(--brk-base-6), var(--brk-base-2));
    transition: all .4s ease;
    border-radius: 50%;
    width: 75px;
    height: 75px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    transform: scale(0); }
    .info-box__wrapper-web .svg-wrap svg {
      width: 45px;
      height: 45px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2; }
      .info-box__wrapper-web .svg-wrap svg + * {
        margin-top: 0; }
  .info-box__wrapper-web p {
    margin-top: 5px; }
  .info-box__wrapper-web svg * {
    stroke: #fff; }
  .info-box__wrapper-web:hover .svg-wrap, .info-box__wrapper-web.current .svg-wrap {
    transform: scale(1); }
  .info-box__wrapper-web:hover .shape > .before, .info-box__wrapper-web.current .shape > .before {
    animation: 1.5s brk-pulse .3s infinite;
    opacity: .1; }
  .info-box__wrapper-web:hover .shape > .after, .info-box__wrapper-web.current .shape > .after {
    animation: brk-pulse 1.5s infinite;
    opacity: .2; }

.wpb_column.current .info-box__wrapper-strict {
  background: var(--brand-primary);
  box-shadow: 0 5px 16px 0 rgba(var(--brand-primary-rgb), 0.5); }
  .wpb_column.current .info-box__wrapper-strict > * {
    color: #fff; }
  .wpb_column.current .info-box__wrapper-strict p {
    color: #fff;
    opacity: 1; }
  .wpb_column.current .info-box__wrapper-strict .show-cont {
    opacity: 1; }
  .wpb_column.current .info-box__wrapper-strict .svg-wrap {
    left: 50%;
    transform: translateX(-50%); }
  .wpb_column.current .info-box__wrapper-strict svg * {
    stroke: #fff; }
  .wpb_column.current .info-box__wrapper-strict .btn-sliding-left .text {
    opacity: 1; }
  .wpb_column.current .info-box__wrapper-strict .btn-sliding-left .border {
    background: #5291FF;
    border-color: #5E99FF;
    width: 100%; }
  .wpb_column.current .info-box__wrapper-strict .btn-sliding-left i {
    color: #fff; }

.info-box__wrapper-strict {
  text-align: left;
  padding: 60px 35px 140px;
  position: relative;
  z-index: 1; }
  .info-box__wrapper-strict > * {
    color: #333; }
  .info-box__wrapper-strict_light > * {
    color: #fff; }
  .info-box__wrapper-strict p {
    color: #9F9F9F;
    line-height: 21px;
    margin-top: 10px;
    transition: all .4s ease; }
  .info-box__wrapper-strict .show-cont {
    opacity: 0; }
  .info-box__wrapper-strict svg {
    width: 80px;
    height: 80px; }
  .info-box__wrapper-strict .svg-wrap {
    display: inline-block;
    position: relative;
    left: 0;
    transition: all .4s ease; }
    .info-box__wrapper-strict .svg-wrap + * {
      margin-top: 42px; }
  .info-box__wrapper-strict svg * {
    stroke: var(--brand-primary); }
  .info-box__wrapper-strict_light svg * {
    stroke: #fff; }
  .info-box__wrapper-strict .btn-sliding-left {
    position: absolute;
    bottom: 40px;
    left: 35px;
    right: 35px;
    color: #fff;
    height: 45px;
    border-radius: 50px;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 14px;
    padding: 10px; }
    .info-box__wrapper-strict .btn-sliding-left .text {
      opacity: 0;
      transition: all .4s ease; }
    .info-box__wrapper-strict .btn-sliding-left .border {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 45px;
      border: 2px solid #E7EEFA !important;
      border-radius: 100px;
      transition: all .4s ease;
      z-index: -1; }
    .info-box__wrapper-strict .btn-sliding-left i {
      position: absolute;
      top: 50%;
      right: 17px;
      margin: 0;
      color: var(--brand-primary);
      font-size: 16px;
      transform: translate(0, -50%);
      transition: all .4s ease; }
    .info-box__wrapper-strict .btn-sliding-left_squared {
      border-radius: 0; }
      .info-box__wrapper-strict .btn-sliding-left_squared .border {
        border-radius: 0; }
      .info-box__wrapper-strict .btn-sliding-left_squared i {
        color: #ffffff; }
  .info-box__wrapper-strict:hover, .info-box__wrapper-strict.current {
    background: var(--brand-primary);
    box-shadow: 0 5px 16px 0 rgba(var(--brand-primary-rgb), 0.5); }
    .info-box__wrapper-strict:hover > *, .info-box__wrapper-strict.current > * {
      color: #fff; }
    .info-box__wrapper-strict:hover p, .info-box__wrapper-strict.current p {
      color: #fff;
      opacity: 1; }
    .info-box__wrapper-strict:hover .show-cont, .info-box__wrapper-strict.current .show-cont {
      opacity: 1; }
    .info-box__wrapper-strict:hover .svg-wrap, .info-box__wrapper-strict.current .svg-wrap {
      left: 50%;
      transform: translateX(-50%); }
    .info-box__wrapper-strict:hover svg *, .info-box__wrapper-strict.current svg * {
      stroke: #fff; }
    .info-box__wrapper-strict:hover .btn-sliding-left .text, .info-box__wrapper-strict.current .btn-sliding-left .text {
      opacity: 1; }
    .info-box__wrapper-strict:hover .btn-sliding-left .border, .info-box__wrapper-strict.current .btn-sliding-left .border {
      background: var(--brand-primary);
      width: 100%; }
    .info-box__wrapper-strict:hover .btn-sliding-left i, .info-box__wrapper-strict.current .btn-sliding-left i {
      color: #fff; }
    .info-box__wrapper-strict:hover .btn-sliding-left_squared .border, .info-box__wrapper-strict.current .btn-sliding-left_squared .border {
      background-color: transparent; }
  .info-box__wrapper-strict_light:hover, .info-box__wrapper-strict_light.current {
    box-shadow: none; }
    .info-box__wrapper-strict_light:hover .svg-wrap, .info-box__wrapper-strict_light.current .svg-wrap {
      left: 0;
      transform: none; }

.wpb_column.current .info-box__wrapper-image {
  box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.4);
  transform: translate(0, -10px); }

.wpb_column.current .info-box__wrapper-image.info-box__wrapper-image_light {
  box-shadow: none; }

.info-box__wrapper-image {
  padding: 66px 20px;
  position: relative;
  z-index: 1;
  background-color: #F4F4F4;
  border-radius: 10px;
  margin: 10px; }
  .info-box__wrapper-image .livicon-evo {
    margin-bottom: 22px; }
  .info-box__wrapper-image p {
    color: #777777;
    margin-top: 15px; }
  .info-box__wrapper-image svg {
    width: 64px;
    height: 64px; }
    .info-box__wrapper-image svg + * {
      margin-top: 22px; }
  .info-box__wrapper-image.light > * {
    color: #fff; }
  .info-box__wrapper-image.light p {
    color: #C4C2C2; }
  .info-box__wrapper-image:hover, .info-box__wrapper-image.current {
    box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.4);
    transform: translate(0, -10px); }

.wpb_column.current .info-box__wrapper-icon {
  box-shadow: 0 20px 60px 0 rgba(var(--brand-primary-rgb), 0.4);
  background: var(--brand-primary); }
  .wpb_column.current .info-box__wrapper-icon svg * {
    stroke: #fff; }
  .wpb_column.current .info-box__wrapper-icon p {
    color: rgba(var(--white-rgb), 0.6); }
  .wpb_column.current .info-box__wrapper-icon .icon-after {
    opacity: 1; }

.info-box__wrapper-icon {
  padding: 40px 15px;
  position: relative;
  z-index: 1;
  border-radius: 10px;
  margin: 10px 0;
  overflow: hidden; }
  @media (min-width: 992px) {
    .info-box__wrapper-icon {
      padding: 66px 15px; } }
  .info-box__wrapper-icon p {
    color: #BCBDBE;
    margin-top: 21px; }
  .info-box__wrapper-icon svg {
    width: 64px;
    height: 64px;
    margin-bottom: 22px; }
    .info-box__wrapper-icon svg + * {
      margin-top: 0; }
  .info-box__wrapper-icon i {
    font-size: 3.25rem;
    margin-bottom: 32px; }
  .info-box__wrapper-icon .livicon-evo {
    margin-bottom: 22px; }
  .info-box__wrapper-icon .icon-after {
    position: absolute;
    bottom: 0;
    right: 0;
    opacity: 0;
    transform: translate(35%, 35%);
    transition: all .4s ease;
    z-index: -1; }
    .info-box__wrapper-icon .icon-after .livicon-evo {
      width: 200px !important; }
    .info-box__wrapper-icon .icon-after svg {
      width: 200px;
      height: 200px;
      opacity: 0.15; }
      .info-box__wrapper-icon .icon-after svg * {
        stroke: #000 !important;
        fill: none; }
  .info-box__wrapper-icon:hover, .info-box__wrapper-icon.current {
    box-shadow: 0 20px 60px 0 rgba(var(--brand-primary-rgb), 0.4);
    background: rgba(var(--brand-primary-rgb), 0.6); }
    .info-box__wrapper-icon:hover svg *, .info-box__wrapper-icon.current svg * {
      stroke: #fff; }
    .info-box__wrapper-icon:hover i, .info-box__wrapper-icon.current i {
      color: #fff; }
    .info-box__wrapper-icon:hover p, .info-box__wrapper-icon.current p {
      color: rgba(var(--white-rgb), 0.6); }
    .info-box__wrapper-icon:hover .icon-after, .info-box__wrapper-icon.current .icon-after {
      opacity: 1; }

.info-box-icon-simple {
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.12);
  background-color: #ffffff; }
  .info-box-icon-simple > * {
    z-index: 1; }
  .info-box-icon-simple > .brk-abs-overlay {
    z-index: 0; }
  .info-box-icon-simple__icon-wrapper {
    width: 170px;
    height: 170px;
    background-color: transparent;
    border-radius: 50%;
    transition: .3s ease background-color;
    position: relative; }
    .info-box-icon-simple__icon-wrapper:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: 2px solid var(--brand-primary);
      opacity: .1; }
    .info-box-icon-simple__icon-wrapper i {
      font-size: 64px;
      transition: .3s ease color;
      color: var(--brand-primary); }
  .info-box-icon-simple__title, .info-box-icon-simple__about {
    transition: .3s ease color, .3s ease opacity; }
  .info-box-icon-simple .brk-abs-overlay {
    opacity: 0;
    transition: .3s ease opacity; }
  .info-box-icon-simple:hover .info-box-icon-simple__icon-wrapper {
    background-color: rgba(255, 255, 255, 0.2); }
    .info-box-icon-simple:hover .info-box-icon-simple__icon-wrapper:before {
      border: 2px solid rgba(255, 255, 255, 0.3);
      opacity: 1; }
    .info-box-icon-simple:hover .info-box-icon-simple__icon-wrapper i {
      color: #fff; }
  .info-box-icon-simple:hover .info-box-icon-simple__title {
    color: #fff; }
  .info-box-icon-simple:hover .info-box-icon-simple__about {
    color: #fff;
    opacity: .8; }
  .info-box-icon-simple:hover .brk-abs-overlay {
    opacity: 1; }
  .info-box-icon-simple:hover .btn {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.3) !important; }
