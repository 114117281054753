@import "../../assets/styles/variables";
@import "../../assets/styles/mixins";

[class*="info-box__wrapper"] {
	transition: all .4s ease;
	p {
		margin-top: 25px;
	}
	svg {
		transition: all .4s ease;
		width: 84px;
		height: 84px;
		& + * {
			margin-top: 10px;
		}
	}

	i[class*='fa-'] {
		font-size: rem(80);

		& + * {
			margin-top: 15px;
		}
	}
}

[class*="col-"]:last-child {
	.info-box__wrapper-outline {
		border-right: 1px solid rgba($white, 0.32);
	}
}

.wpb_column.current .info-box__wrapper-chess-chess {
	&:after {
		height: 100%;
	}
	&.bottom .show-cont {
		bottom: 100%;
		transform: translateY(100%);
	}
	.show-cont {
		&.overlay__gradient > .overlay_after {
			opacity: 0 !important;
		}
		> .after {
			opacity: 0;
		}
		> * {
			color: $gray-dark;
		}
	}
	svg * {
		stroke: var(--brand-primary);
	}
	.move-cont {
		opacity: 1;
	}
}

.info-box__wrapper-chess {
	position: relative;
	z-index: 1;
	min-height: 430px;
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 0;
		transition: all .4s ease;
		background: $white;
		box-shadow: 0 6px 30px 0 rgba($black, 0.12);
		z-index: -1;
	}
	&.bottom {
		&:after {
			bottom: 0;
			top: auto;
		}
		.show-cont {
			bottom: 0;
			top: auto;
		}
	}
	.btn {
		margin: 40px 0 0;
		min-width: 0;
		width: 100%;
	}
	.show-cont {
		padding: 65px 30px;
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		transition: all .4s ease;
		z-index: 1;
		> * {
			transition: all .4s ease;
		}
		> .after {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: -1;
			transition: all .4s ease;
		}
		&.overlay__gradient > .overlay_after {
			transition: all .4s ease;
		}
		> * {
			color: $white;
		}
	}
	.move-cont {
		position: absolute;
		left: 30px;
		right: 30px;
		bottom: 30px;
		opacity: 0;
		transition: all .4s ease;
	}
	.livicon-evo {
		margin-bottom: 10px;
	}
	svg * {
		stroke: $white;
	}

	@media(max-width: 991px) {
		&:after {
			height: 100%;
		}
		&.bottom .show-cont {
			bottom: 100%;
			transform: translateY(100%);
		}
		.show-cont {
			&.overlay__gradient > .overlay_after {
				opacity: 0 !important;
			}
			> .after {
				opacity: 0;
			}
			> * {
				color: $gray-dark;
			}
		}
		svg * {
			stroke: var(--brand-primary);
		}
		.move-cont {
			opacity: 1;
		}
	}
	&:hover, &.current {
		&:after {
			height: 100%;
		}
		&.bottom .show-cont {
			bottom: 100%;
			transform: translateY(100%);
		}
		.show-cont {
			&.overlay__gradient > .overlay_after {
				opacity: 0 !important;
			}
			> .after {
				opacity: 0;
			}
			> * {
				color: $gray-dark;
			}
		}
		i[class*='fa-'] {
			color: var(--brand-primary);
		}
		svg * {
			stroke: var(--brand-primary);
		}
		.move-cont {
			opacity: 1;
		}
	}
}

.wpb_column.current .info-box__wrapper-outline {
	&:after {
		top: -30px;
		left: -10px;
		right: -10px;
		bottom: -30px;
		opacity: 1;
	}
	.move-cont {
		transform: translateY(-50px);
		> * {
			color: $gray-dark;
		}
		p {
			color: #9F9F9F;
			opacity: 1;
		}
	}
	svg * {
		stroke: var(--brand-primary);
	}
}

.info-box__wrapper-outline {
	border: 1px solid rgba($white, 0.32);
	padding: 85px 20px;
	position: relative;
	z-index: 1;
	@media(min-width: 992px) {
		border-right: none;
	}
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $white;
		z-index: -1;
		opacity: 0;
		transition: all .4s ease;
		box-shadow: 0 6px 30px 0 rgba($black, 0.12);
	}
	p {
		position: absolute;
		opacity: 0;
	}
	.move-cont {
		transition: all .4s ease;
		> * {
			transition: all .4s ease;
			color: $white;
		}
	}
	.livicon-evo {
		margin-bottom: 10px;
	}
	svg * {
		stroke: $white;
	}
	&:hover, &.current {
		&:after {
			top: -30px;
			left: -10px;
			right: -10px;
			bottom: -30px;
			opacity: 1;
		}
		.move-cont {
			transform: translateY(-50px);
			> * {
				color: $gray-dark;
			}
			p {
				color: #9F9F9F;
				opacity: 1;
			}
		}
		svg * {
			stroke: var(--brand-primary);
		}
	}
}

.wpb_column.current .info-box__wrapper-gradient {
	z-index: 2;
	&:after {
		top: -20px;
		left: -10px;
		right: -10px;
		bottom: -20px;
		opacity: 1;
	}
	.livicon-evo {
		width: 70px !important;
	}
	.move-cont {
		transform: translateY(-60px);
		.show-cont {
			color: #9F9F9F;
			opacity: 1;
		}
	}
	.svg-wrap {
		background: #eef4ff;
	}
	svg {
		width: 65px;
		height: 65px;
	}
}

.info-box__wrapper-gradient {
	border: 1px solid rgba(var(--brand-primary-rgb), 0.12);
	padding: 85px 20px;
	position: relative;
	z-index: 1;
	background: $white;
	@media(min-width: 992px) {
		border-right: none;
	}
	.livicon-evo {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: all .4s ease;
		svg {
			transform: translate(0, 0) !important;
		}
	}
	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: $white;
		z-index: -1;
		opacity: 0;
		transition: all .4s ease;
		box-shadow: 0 6px 30px 0 rgba(var(--brand-primary-rgb), 0.4);
	}
	.svg-wrap {
		position: relative;
		z-index: 1;
		display: inline-block;
		background: transparent;
		transition: all .4s ease;
		border-radius: 50%;
		width: 120px;
		height: 120px;
		& + * {
			margin-top: 25px;
		}
		svg {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	p {
		margin-top: 5px;
	}
	.show-cont {
		position: absolute;
		opacity: 0;
		width: 100%;
	}
	.move-cont {
		transition: all .4s ease;
		> * {
			transition: all .4s ease;
		}
	}
	svg {
		width: 120px;
		height: 120px;
	}
	svg * {
		stroke: var(--brand-primary);
	}
	&:hover, &.current {
		z-index: 2;
		&:after {
			top: -20px;
			left: -10px;
			right: -10px;
			bottom: -20px;
			opacity: 1;
		}
		.livicon-evo {
			width: 70px !important;
		}
		.move-cont {
			transform: translateY(-60px);
			.show-cont {
				color: #9F9F9F;
				opacity: 1;
			}
		}
		.svg-wrap {
			background: #eef4ff;
		}
		svg {
			width: 65px;
			height: 65px;
		}
	}
}

.wpb_column.current .info-box__wrapper-shuffle {
	z-index: 2;
	padding-top: 75px;
	padding-bottom: 75px;
	&.bottom {
		padding-bottom: 150px;
		.after-bg:after {
			top: 0;
		}
	}
	> * {
		color: $white;
		> * {
			color: $white;
		}
	}
	.after-bg {
		opacity: 0.94;
		height: 100%;
		> .after {
			opacity: 1;
		}
		&:after {
			bottom: 0;
		}
	}
	.btn {
		opacity: 1;
	}
	.svg-wrap {
		width: 135px;
		height: 135px;
		border-color: rgba($white, 0.3);
		& + * {
			margin-top: 32px;
		}
	}
	svg * {
		stroke: $white;
	}
}

.info-box__wrapper-shuffle {
	margin: 30px 0;
	position: relative;
	z-index: 1;
	min-height: 520px;
	padding: 27px 30px;
	box-shadow: 0 6px 30px 0 rgba($black, 0.12);
	@media(min-width: 768px) {
		margin: 0;
	}
	// *{ transition: all .4s ease; }
	&.bottom {
		display: flex;
		align-items: flex-end;
		.after-bg {
			bottom: 0;
			top: auto;
			&:after {
				bottom: auto;
				top: -25px;
				border-width: 0 30px 25px 30px;
				border-color: transparent transparent $white transparent;
			}
		}
		.img-bottom {
			top: 0;
			bottom: auto;
		}
		.img-top {
			bottom: 0;
			top: auto;
		}
	}
	.livicon-evo {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		svg {
			transform: translate(0, 0) !important;
		}
	}
	.img-bottom, .img-top {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 50%;
		object-fit: cover;
		z-index: -1;
		pointer-events: none;
	}
	.img-top {
		transform: rotate(180deg);
		top: 0;
		bottom: auto;
	}
	.svg-wrap {
		width: 70px;
		height: 70px;
		position: relative;
		margin: 0 auto;
		border: 3px solid transparent;
		border-radius: 50%;
		transition: all .4s ease;
		& + * {
			margin-top: 12px;
		}
		svg {
			width: 70px;
			height: 70px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	.after-bg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 50%;
		background: $white;
		transition: all .4s ease;
		z-index: -1;
		&:after {
			content: '';
			position: absolute;
			bottom: -25px;
			left: 50%;
			transform: translateX(-50%);
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 25px 30px 0 30px;
			border-color: $white transparent transparent transparent;
			transition: all .4s ease;
			z-index: -1;
		}
		> .after {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background: linear-gradient(to top, var(--brand-primary), var(--brk-base-2));
			transition: all .4s ease;
			opacity: 0;
		}
	}
	p {
		color: #9F9F9F;
		margin-top: 10px;
		line-height: 21px;
	}
	.btn {
		position: absolute;
		left: 50%;
		transform: translate(-50%, 0);
		margin: 40px 0 0;
		opacity: 0;
		transition: all .4s ease;
	}
	svg * {
		stroke: var(--brand-primary);
	}
	&:hover, &.current {
		z-index: 2;
		padding-top: 75px;
		padding-bottom: 75px;
		&.bottom {
			padding-bottom: 150px;
			.after-bg:after {
				top: 0;
			}
		}
		> * {
			color: $white;
			> * {
				color: $white;
			}
		}
		.after-bg {
			opacity: 0.94;
			height: 100%;
			> .after {
				opacity: 1;
			}
			&:after {
				bottom: 0;
			}
		}
		.btn {
			opacity: 1;
		}
		.svg-wrap {
			width: 135px;
			height: 135px;
			border-color: rgba($white, 0.3);
			& + * {
				margin-top: 32px;
			}
		}
		svg * {
			stroke: $white;
		}
	}
}

.wpb_column.current .info-box__wrapper-web {
	.svg-wrap {
		transform: scale(1);
	}
	.shape {
		> .before {
			animation: 1.5s brk-pulse .3s infinite;
			opacity: .1;
		}
		> .after {
			animation: brk-pulse 1.5s infinite;
			opacity: .2;
		}
	}
}

.info-box__wrapper-web {
	text-align: left;
	padding: 50px 20px;
	position: relative;
	z-index: 1;
	display: flex;
  align-items: center;
	@media(min-width: 992px) {
		padding: 70px 20px;
	}
	.content {
		margin-left: 22px;
		flex: 1;
		> * {
			color: $white;
		}
		p {
			color: rgba(var(--white-rgb), .6);
			margin-top: 15px;
		}
	}
	.livicon-evo {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		svg {
			transform: translate(0, 0) !important;
		}
	}
	.shape {
		position: relative;
		z-index: 1;
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 15px;
			height: 15px;
			border: 2px solid $white;
			border-radius: 50%;
		}
		&:after {
			position: absolute;
			bottom: calc(50% + 7px);
			left: calc(50% - 1px);
			width: 2px;
			height: 100vh;
			background: $white;
			@media(min-width: 992px) {
				content: '';
			}
		}
		> .before {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transform: scale(1.35);
			opacity: 0;
			background-color: $white;
			transition: all .4s ease;
			border-radius: 50%;
			z-index: -1;
		}
		> .after {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			background-color: $white;
			transition: all .4s ease;
			border-radius: 50%;
			z-index: -1;
		}
	}
	.svg-wrap {
		position: relative;
		z-index: 1;
		display: inline-block;
		background: linear-gradient(to top, var(--brk-base-6), var(--brk-base-2));
		transition: all .4s ease;
		border-radius: 50%;
		width: 75px;
		height: 75px;
		box-shadow: 0 0 10px 0 rgba($black, 0.15);
		transform: scale(0);
		svg {
			width: 45px;
			height: 45px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;
			& + * {
				margin-top: 0;
			}
		}
	}
	p {
		margin-top: 5px;
	}
	svg * {
		stroke: $white;
	}
	&:hover, &.current {
		.svg-wrap {
			transform: scale(1);
		}
		.shape {
			> .before {
				animation: 1.5s brk-pulse .3s infinite;
				opacity: .1;
			}
			> .after {
				animation: brk-pulse 1.5s infinite;
				opacity: .2;
			}
		}
	}
}

.wpb_column.current .info-box__wrapper-strict {
	background: var(--brand-primary);
	box-shadow: 0 5px 16px 0 rgba(var(--brand-primary-rgb), 0.5);
	> * {
		color: $white;
	}
	p {
		color: $white;
		opacity: 1;
	}
	.show-cont {
		opacity: 1;
	}
	.svg-wrap {
		left: 50%;
		transform: translateX(-50%);
	}
	svg * {
		stroke: $white;
	}
	.btn-sliding-left {
		.text {
			opacity: 1;
		}
		.border {
			background: #5291FF;
			border-color: #5E99FF;
			width: 100%
		}
		i {
			color: $white;
		}
	}
}

.info-box__wrapper-strict {
	text-align: left;
	padding: 60px 35px 140px;
	position: relative;
	z-index: 1;
	> * {
		color: $gray-dark;
  }
  &_light > * {
    color: #fff;
  }
	p {
		color: #9F9F9F;
		line-height: 21px;
		margin-top: 10px;
		transition: all .4s ease;
	}
	.show-cont {
		opacity: 0;
	}
	svg {
		width: 80px;
		height: 80px;
	}
	.svg-wrap {
		display: inline-block;
		position: relative;
		left: 0;
		transition: all .4s ease;
		& + * {
			margin-top: 42px;
		}
	}
	svg * {
		stroke: var(--brand-primary);
  }
  &_light svg *{
    stroke: #fff;
  }
	.btn-sliding-left {
		position: absolute;
		bottom: 40px;
		left: 35px;
		right: 35px;
		color: $white;
		height: 45px;
		border-radius: 50px;
		margin: 0;
		font-family: 'Open Sans', sans-serif;
		font-weight: 700;
		font-size: 14px;
		padding: 10px;
		.text {
			opacity: 0;
			transition: all .4s ease;
		}
		.border {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			width: 45px;
			border: 2px solid #E7EEFA !important;
			border-radius: 100px;
			transition: all .4s ease;
			z-index: -1;
		}
		i {
			position: absolute;
			top: 50%;
			right: 17px;
			margin: 0;
			color: var(--brand-primary);
			font-size: 16px;
			transform: translate(0, -50%);
			transition: all .4s ease;
    }
    
    &_squared {
      border-radius: 0;

      .border {
        border-radius: 0;
      }

      i {
        color: #ffffff;
      }
    }
  }
  
	&:hover, &.current {
		background: var(--brand-primary);
		box-shadow: 0 5px 16px 0 rgba(var(--brand-primary-rgb), 0.5);
		> * {
			color: $white;
		}
		p {
			color: $white;
			opacity: 1;
		}
		.show-cont {
			opacity: 1;
		}
		.svg-wrap {
			left: 50%;
			transform: translateX(-50%);
		}
		svg * {
			stroke: $white;
		}
		.btn-sliding-left {
			.text {
				opacity: 1;
			}
			.border {
				background: var(--brand-primary);
				width: 100%;
			}
			i {
				color: $white;
      }

      &_squared {
        .border {
          background-color: transparent;
        }
      }
		}
  }
  
  &_light:hover,
  &_light.current {
    box-shadow: none;
    .svg-wrap {
			left: 0;
			transform: none;
		} 
  }
}

.wpb_column.current .info-box__wrapper-image {
	box-shadow: 0 20px 60px 0 rgba($black, 0.4);
	transform: translate(0, -10px);
}
.wpb_column.current .info-box__wrapper-image.info-box__wrapper-image_light {
	box-shadow: none;
}

.info-box__wrapper-image {
	padding: 66px 20px;
	position: relative;
	z-index: 1;
	background-color: #F4F4F4;
	border-radius: 10px;
	margin: 10px;
	.livicon-evo {
		margin-bottom: 22px;
	}
	p {
		color: #777777;
		margin-top: 15px;
	}
	svg {
		width: 64px;
		height: 64px;
		& + * {
			margin-top: 22px;
		}
	}
	// svg *{ fill: var(--brand-primary); }
	&.light {
		> * {
			color: $white;
		}
		p {
			color: #C4C2C2;
		}
		// svg *{ fill: $white; }
	}
	&:hover, &.current {
		box-shadow: 0 20px 60px 0 rgba($black, 0.4);
		transform: translate(0, -10px);
	}
}

.wpb_column.current .info-box__wrapper-icon {
	box-shadow: 0 20px 60px 0 rgba(var(--brand-primary-rgb), 0.4);
	background: var(--brand-primary);
	svg * {
		stroke: $white;
	}
	p {
		color: rgba(var(--white-rgb),.6);
	}
	.icon-after {
		opacity: 1;
	}
}

.info-box__wrapper-icon {
	padding: 40px 15px;
	position: relative;
	z-index: 1;
	border-radius: 10px;
	margin: 10px 0;
	overflow: hidden;
	@media(min-width: 992px) {
		padding: 66px 15px;
	}
	p {
		color: #BCBDBE;
		margin-top: 21px;
	}
	svg {
		width: 64px;
		height: 64px;
		margin-bottom: 22px;
		& + * {
			margin-top: 0;
		}
	}
	i {
		font-size: rem(52);
		margin-bottom: 32px;
	}
	.livicon-evo {
		margin-bottom: 22px;
	}
	// svg *{ fill: var(--brand-primary); }
	.icon-after {
		position: absolute;
		bottom: 0;
		right: 0;
		opacity: 0;
		transform: translate(35%, 35%);
		transition: all .4s ease;
		z-index: -1;
		.livicon-evo {
			width: 200px !important;
		}
		svg {
			width: 200px;
			height: 200px;
			opacity: 0.15;
			* {
				stroke: $black !important;
				fill: none;
			}
		}
	}
	&:hover, &.current {
		box-shadow: 0 20px 60px 0 rgba(var(--brand-primary-rgb), 0.4);
		background: rgba(var(--brand-primary-rgb), .6);
		svg * {
			stroke: $white;
		}
		i {
			color: #fff;
		}
		p {
			color: rgba(var(--white-rgb),.6);
		}
		.icon-after {
			opacity: 1;
		}
	}
}

.info-box-icon-simple{
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  >*{
    z-index: 1;
  }
  >.brk-abs-overlay{
    z-index: 0;
  }
  &__icon-wrapper{
    width: 170px;
    height: 170px;
		//border: 2px solid var(--form-color-1);
    background-color: transparent;
    border-radius: 50%;
    transition: .3s ease background-color;
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			border-radius: 50%;
			border: 2px solid var(--brand-primary);
			opacity: .1;
		}
    
    i {
      font-size: 64px;
      transition: .3s ease color;
			color: var(--brand-primary);
    }
  }
  &__title,
  &__about{
    transition: .3s ease color, .3s ease opacity;
  }
  .brk-abs-overlay{
    opacity: 0;
    transition: .3s ease opacity;
  }

  &:hover {
    .info-box-icon-simple__icon-wrapper {
      background-color: rgba(256,256,256, .2);

			&:before {
				border: 2px solid rgba(#fff, .3);
				opacity: 1;
			}

      i {
        color: #fff;
      }
    }
    .info-box-icon-simple__title{
      color: $white;
    }
    .info-box-icon-simple__about{
      color: $white;
      opacity: .8;
    }
    .brk-abs-overlay{
      opacity: 1;
    }

		.btn {
			box-shadow: 0 0 0 2px rgba(#fff, 0.3) !important;
		}
  }
}